// Index
// 1. Popover Dropdown Enable and Disable
// 2. Drop down inside of table set to overlay and append to body
// 3. For Toggle Password eye open and close and password field to text field
// 4. For Toggle choose file on select file

$(function () {
  /* Popover Dropdown Enable and Disable */

  $('body')
    .popover({
      trigger: 'hover focus',
      sanitize: false,
      html: true,
      animation: true,
      selector: '.popover-hover-focus'
    })
    .on('click', '.popover-hover-focus', () => {
      $('.popover').remove();
      $(this).popover('dispose');
      $('.popover-hover-focus').css('pointer-event', 'none');
      $('html, body').animate({ scrollTop: 0 }, '300');
    });

  $(function () {
    $('body').tooltip({
      container: 'body',
      boundary: 'window',
      selector: '[data-toggle="tooltip"]'
    });
  });

  $('html').on('click', function (e) {
    if (typeof $(e.target).data('original-title') == 'undefined' && !$(e.target).parents().is('.popover.in')) {
      $('[data-original-title]').popover('hide');
    }
  });
  /* Popover Dropdown Enable and Disable Ends */

  /* Drop down inside of table set to overlay and append to body */
  $(document).on('shown.bs.dropdown', '.table-responsive', function (e) {
    /* The .dropdown container */
    var $container = $(e.target);

    /* Find the actual .dropdown-menu */
    var $dropdown = $container.find('.dropdown-menu');
    if ($dropdown.length) {
      /* Save a reference to it, so we can find it after we've attached it to the body */
      $container.data('dropdown-menu', $dropdown);
    } else {
      $dropdown = $container.data('dropdown-menu');
    }

    $dropdown.css('top', $container.offset().top + $container.outerHeight() + 'px');
    $dropdown.css('left', $container.offset().left + 'px');
    $dropdown.css('position', 'absolute');
    $dropdown.css('display', 'block');
    $dropdown.appendTo('body');
  });

  $(document).on('hide.bs.dropdown', '.table-responsive', function (e) {
    /* Hide the dropdown menu bound to this button */
    $(e.target).data('dropdown-menu').css('display', 'none');
  });
  /* Drop down inside of table set to overlay and append to body Ends */

  /* For Toggle Password eye open and close and password field to text field */
  $(document).on('click', '.toggle-password', function () {
    $(this).children('i').toggleClass('flaticon-eye-hide flaticon-eye');
    var input = $($(this).attr('toggle'));
    if (input.attr('type') == 'password') {
      input.attr('type', 'text');
    } else {
      input.attr('type', 'password');
    }
  });
  /* For Toggle Password eye open and close and password field to text field Ends */

  /* For Toggle choose file on select file */
  $(document).on('change', '.custom-file-input', function () {
    //get the file name
    let fileName = $(this).val();
    //replace the "Choose a file" label
    $(this).next('.custom-file-label').html(fileName);
  });
  /* For Toggle choose file on select file Ends */

  /* Navbar Hide in small devices when click on it's buttons */
  $(document).on('click', '.nav-link', function () {
    if (!$(this).parent().hasClass('dropdown')) $('.navbar-collapse').collapse('hide');
    $('body').removeClass('navbar-is-expanded');
  });

  $(document).on('click', '.scrollToTop', function () {
    $('html, body').animate({ scrollTop: 0 }, '300');
  });

  if (window.history && window.history.pushState) {
    $(window).on('popstate', function () {
      $('.modal').modal('hide');
      $('.ck-mentions').remove();
    });
  }
  /* Navbar Hide in small devices when click on it's buttons Ends */

  $(document).on('shown.bs.collapse','.navbar', function () {
    $('body').addClass('navbar-is-expanded');
  });

  $(document).on('hide.bs.collapse','.navbar', function () {
    $('body').removeClass('navbar-is-expanded');
  })
});
